import React, { useState } from 'react';
import axios from 'axios';
import { DropzoneArea } from 'react-mui-dropzone';
import { Button, Typography } from '@mui/material';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { getWithExpiry } from '../../common/util/localstorage';

const FirmwareUpload = ({ displayId }) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [firmwareUrl, setFirmwareUrl] = useState(null);
  const t = useTranslation();

  // Handle file selection
  const onChange = (files) => {
    if (files && files.length > 0) {
      const selectedFile = files[0];
      console.log('Selected file:', selectedFile); // Debugging log
      if (selectedFile.name.endsWith('.bin')) {
        setFile(selectedFile);
        setMessage(''); // Clear any previous error message
      } else {
        setFile(null);
        setMessage(t('sharedInvalidFileFormat')); // Show an error for invalid file type
      }
    } else {
      setFile(null);
    }
  };

  // Handle file upload
  const onSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage(t('sharedNoFileSelected'));
      return;
    }

    const formData = new FormData();
    formData.append('firmware', file); // Ensure this key matches the backend field name

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_NAME}/displays/${displayId}/uploadfirmware`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${getWithExpiry('TOKEN')}`,
            'X-Base-URL': process.env.REACT_APP_URL_NAME,
          },
        }
      );
      setMessage(t('sharedUploadSuccessful'));
      setFirmwareUrl(res.data.firmwareUrl || null); // Display firmware download link if provided
    } catch (err) {
      const errorMsg = err.response?.data?.message || t('sharedServerError');
      setMessage(errorMsg);
      console.error(err);
    }
  };

  return (
    <div>
      <DropzoneArea
        dropzoneText={t('sharedDropzoneText')}
        acceptedFiles={['.bin']}
        filesLimit={1}
        onChange={onChange}
        showAlerts={true}
        maxFileSize={10 * 1024 * 1024} // 1 MB limit
      />
      {file && (
        <Typography variant="body1" style={{ margin: '20px 0' }}>
          {t('sharedSelectedFile')}: {file.name}
        </Typography>
      )}
      <Button
        variant="outlined"
        onClick={onSubmit}
        disabled={!file}
        style={{ marginTop: '10px' }}
      >
        {t('sharedUpload')}
      </Button>
      {message && (
        <Typography color={message.includes('Error') ? 'error' : 'primary'} variant="body1" style={{ marginTop: '10px' }}>
          {message}
        </Typography>
      )}

      {firmwareUrl && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="body1">{t('sharedDownloadFirmwareFile')}:</Typography>
          <a href={firmwareUrl} target="_blank" rel="noopener noreferrer">
            {firmwareUrl}
          </a>
        </div>
      )}
    </div>
  );
};

export default FirmwareUpload;
