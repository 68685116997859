import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffectAsync } from "../../reactHelper";
import DisplaysFirmware from "./displaysfirmware";
import { displaysActions } from "../../store";
import { getWithExpiry } from "../../common/util/localstorage";

export default function OTADisplays() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("Error !!");
    const _userId= useSelector((state) => state.session.user._id);
    const _displays = useSelector((state) => state.displays.items);

    useEffectAsync(async () => {
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_URL_NAME}/userdisplays?userId=${_userId}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getWithExpiry("TOKEN")}`,

                    },
                }
            );
            if (response.ok) {
                const data = await response.json();
               
                dispatch(displaysActions.setItems(data));
                return;
            }
            if (response.status === 401) {
                setErrorMsg("UNAUTHORIZED");
                navigate("/login");
            } else {
                setErrorMsg(await response.text());
                throw Error(await response.text());
            }
        } finally {
            setLoading(false);
        }
    }, [, dispatch]);

    return (
        <>
            <DisplaysFirmware
            />
        </>
    );
}
