import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import DisplayFirmwareUploadPage from './displayFirmwareUpload';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { getWithExpiry } from '../../common/util/localstorage';
import { displaysActions } from '../../store';

const DisplaysFirmware = () => {
  const dispatch = useDispatch();
  const _displays = useSelector((state) => state.displays.items);
  const _userId = useSelector((state) => state.session.user._id);

  const [error, setError] = useState('');
  const [updateDisplay, setUpdateDisplay] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const t = useTranslation();

  // Fetch displays data
  const fetchDisplays = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/userdisplays?userId=${_userId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getWithExpiry('TOKEN')}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch displays');
      }

      const data = await response.json();
      dispatch(displaysActions.setItems(data));
    } catch (err) {
      setError('Failed to load displays');
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDisplays();
  }, [dispatch, _userId]);

  // Handle delete firmware
  const deleteFirmware = async (firmwareName, displayId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_URL_NAME}/firmware/${firmwareName}`);
      fetchDisplays(); // Refresh displays after deletion
    } catch (err) {
      setError('Failed to delete firmware');
      console.error(err);
    }
  };

  // Open modal for firmware upload
  const handleOpenModal = (display) => {
    setUpdateDisplay(display);
    setOpenModal(true);
  };

  // Close modal and refresh displays
  const handleCloseModal = () => {
    setOpenModal(false);
    setUpdateDisplay(null);
    fetchDisplays();
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        {"Display Firmware"}
      </Typography>
      {error && <Typography color="error">{error}</Typography>}

      <Grid container spacing={3}>
        {_displays?.map((display) => (
          <Grid item xs={12} sm={6} md={4} key={display._id}>
            <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {display.display_name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {"Display ID"}: {display.display_id}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {"updated At"}: {display.firmware_updatedAt || t('sharedNoData')}
                </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: 'flex-end' }}>
                {!display.firmware_name || !display.firmware_url ? (
                  <>
                    <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                      <VolumeOffIcon fontSize="small" style={{ marginRight: 4 }} />
                      {"No Firmware"}
                    </Typography>
                    <IconButton color="primary" onClick={() => handleOpenModal(display)}>
                      <UploadIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton style={{ color: 'red' }} onClick={() => deleteFirmware(display.firmware_name, display._id)}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton style={{ color: 'green' }} onClick={() => handleOpenModal(display)}>
                      <UploadIcon />
                    </IconButton>
                  </>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal for firmware upload */}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
        <DialogTitle>{'shareduploadFirmwareForDisplay'}</DialogTitle>
        <DialogContent>
          {updateDisplay && <DisplayFirmwareUploadPage display={updateDisplay} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            {t('sharedClose')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DisplaysFirmware;
