import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FirmwareUpload from "./firmwareUpload";
import { useTranslation } from "../../common/components/LocalizationProvider";

const DisplayFirmwareUploadPage = ({ display }) => {
  const [firmwareURL, setFirmwareURL] = useState(null);
  
const t =useTranslation()
  useEffect(() => {
    if (display && display.firmware_name) {
      setFirmwareURL(`${process.env.REACT_APP_URL_NAME}/firmware/${display.firmware_name}`);
    } else {
      setFirmwareURL(null);
    }
  }, [display]);

  return (
    <>
      {display && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedfirmwareFile")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FirmwareUpload displayId={display._id} /> {/* Pass the displayId to firmwareUpload */}
             
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </>
  );
};

export default DisplayFirmwareUploadPage;
