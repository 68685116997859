import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PreviewIcon from '@mui/icons-material/Preview';

import MailIcon from '@mui/icons-material/Mail';
import { useSelector } from 'react-redux';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';

import { Link, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  agenciesActions,
  sessionActions,
  tripsActions,
  usersActions,
  displaysActions
} from '../store/';
import LogoutIcon from '@mui/icons-material/Logout';

import RouteIcon from '@mui/icons-material/Route';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';

import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { useTranslation } from '../common/components/LocalizationProvider';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import { EmergencyRecording } from '@mui/icons-material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const t = useTranslation()
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const _isAdministrator = useSelector(
    (state) => state.session.user.administrator
  );
  const _sonoModule = useSelector(
    (state) => state.session.user.sono_sys
  );
  const _isSuperAdmin = useSelector(
    (state) => state.session.user.role
  );
  const _username = useSelector((state) => state.session.user.username);
  const [menuItems, setMenuItems] = useState(
    _isSuperAdmin === "superadmin"
      ? [
        'agences',
        'trips',
        'displays',
        'users',
        'claims',
        'speech',
        'tripsound',
        'displaysota'

      ] : _isAdministrator === true
        ? [
          'agences',
          'trips',
          'displays',
          'users',
          'speech',
          'tripsound',
          'displaysota'

        ]
        : _isAdministrator === false&&_sonoModule==true?[
          'agences',
          'trips',
          'displays',
          'contactus',
          'speech',
          'tripsound'

        ]:[
          'agences',
          'trips',
          'displays',
          'contactus',

        ]
  );


  //console.log("IS ADMIN :: ",_isAdministrator)



  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    localStorage.removeItem('TOKEN');
    navigate('/login');
    dispatch(sessionActions.updateUser({ user: {} }));
    dispatch(tripsActions.setItems({}));
    dispatch(agenciesActions.setItems({}));
    dispatch(usersActions.setItems({}));
    dispatch(displaysActions.setItems({}));

  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ backgroundColor: '#192841' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" noWrap component="div">
            LED DISPLAYS
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="div"
            style={{ color: 'white', marginLeft: 'auto', marginRight: '5px' }}
          >
            {_username}
          </Typography>
          <IconButton
            variant="outlined"
            size="meduim"
            style={{ backgroundColor: 'white', color: 'red' }}
            onClick={logout}
          >
            <LogoutIcon fontSize="inherit" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((text, index) => (
            <ListItem
              key={text}
              component={Link}
              to={'/settings/' + text}
              disablePadding
              sx={{ display: 'block', color: 'black' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {text === 'users' ? (
                    <PeopleOutlineIcon style={{ color: '#192841' }} />
                  ) : text === 'agences' ? (
                    <EmojiTransportationIcon style={{ color: '#192841' }} />
                  ) : text === 'trips' ? (
                    <RouteIcon style={{ color: '#192841' }} />
                  ) :
                    text === 'contactus' ? (
                      <ContactMailIcon style={{ color: '#192841' }} />
                    ) : text === 'claims' ? (
                      <ContactEmergencyIcon style={{ color: '#192841' }} />
                    ) : text === 'speech' ? (
                      <VolumeUpIcon style={{ color: '#192841' }} />
                      
                    ): text === 'tripsound' ? (
                      <VolumeUpIcon style={{ color: '#192841' }} />
                      
                    )
                    : text === 'displaysota' ? (
                      <SmartDisplayIcon style={{ color: '#192841' }} />
                      
                    ): (
                      <SmartDisplayIcon style={{ color: '#192841' }} />
                    )}
                </ListItemIcon>
                <ListItemText primary={text == "agences" ? t('sharedAgencies') : text == "users" ? t('sharedUsers') : text == "trips" ? t('sharedTrips') : text == "contactus" ? t("sharedContactUs"):text == "speech" ? t('sharedTextToSpeech'):text == "tripsound" ? t('sharedSpeech') :text == "displaysota" ? "OTA" :t('sharedDisplays')} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
              <Divider />
            </ListItem>
          ))}


        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Outlet />
      </Box>
    </Box>
  );
}
